import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { homeSliderImage } from "../index.module.scss"
import { StaticImage } from "gatsby-plugin-image"

const HistoryPage = () => (
  <Layout>
    <Seo title="Geschichte Weihnachtskrippe Dürrenwaldstetten" />
    <h1>Die Geschichte der Weihnachtskrippe Dürrenwaldstetten</h1>

    <div className="stage-image">
      <StaticImage
        src="../../images/geschichte/krippe-duerrenwaldstetten-ansicht.jpg"
        quality={95}
        width={1000}
        formats={["auto", "webp", "avif"]}
        alt="A Gatsby astronaut"
        aspectRatio={21 / 9}
      />
    </div>

    <p className="normal">
      Nach einer umfangreichen 2002- 2003 Innenrestauration der St. Jakobuskirche in Dürrenwaldstetten, war der Wunsch der Bevölkerung die bisherige  Krippe
      durch eine neue zu ersetzen. Für diesen Zweck waren schon einige Spenden eingegangen. Für den dortmaligen Pfarrer Speck sowie dem gesamten
      Kirchengemeinderat war klar, dem Wunsch der Bürger und Spendern zu entsprechen - das heißt eine neue Weihnachtskrippe anzuschaffen.
    </p>
    <p className="normal">
      Das war für uns alle eine große Herausforderung. Man bildete aus Pfarrer und einigen Personen des Kirchengemeinderates ingesamt 6 Personen,
      die sogenannte Kripplesgruppe (Krippenfreunde). Diese fuhren dann während der Weihnachtszeit zu Krippenaustellungen, Krippenbauern, Figurenschnitzern und
      vieles mehr, um sich ein Bild zu machen.
    </p>
    <p className="normal">
      Die alte Krippe war, wenn man so will eine Krippe im ländlichen Stil. Wir wussten Anfangs nicht welchen Weg wir gehen sollen bis wir bei einer Krippenaustellung
      in der ehemaligen Kirche in Rulfingen eine Krippe im Orientlalischen Stil mit echten Angela Trippi Terrakotta Figuren endeckt haben. Alle waren begeistert.
      Dies war das was wir suchten. Diese Krippe war von Herrn Aust aus Jettingen ausgestellt mit dem wir anschliesend Kontakt aufnahmen. In seiner Ausstellung fanden
      wir eine sehr schöne heilige Familie, welche den Start für unsere neue Orientalische Krippe legte.
    </p>
    <p className="normal">
      Da wir vom Orientalischen Krippenbau wenig Ahnung hatten, baten wir Herrn Aust beim ersten Abschnitt um Hilfe. Dieser Abschnitt stellt die Geburt Jesus dar. So stand
      der erste Teil der Krippe an Weihnachten 2004 auf dem Josefsaltar. Schon im folgenden Jahr traute sich die Kripplesgruppe eine  Weiterentwicklung bzw. Anbau selbst in die Hand zu nehmen.
      Die Grundfläche der Krippe wurde in der Tiefe fast verdoppelt. Außerdem wurde die  Rückwand mit der Geburtstätte Bethlehem sowie umliegende Landschaft bemalt.
      Herr Weindl, Hobbymaler aus Belingen ebenfals Kammeltal, stellte hier sein Können unter Beweis zu aller Zufriedenheit. Der erste Anbau ist gelungen!
    </p>
    <p className="normal">
      Zwei Jahre später folgte der nächste  größere Anbau in eigener Regie. Wir hatten ja Bethlehem mit Geburtsstätte (Stall) eine schlichte einfache Gegend. Der Anbau mußte
      das Gegenteil sein, Feudal u. Prunk sprich Reichtum - und was passt besser als der Tempel des Herodes. So entstand der Herodestempel mit Herodes auf dem Trohn. Neben ihm
      links u. rechts 2 Leoparden (als Machtsymbol). Der Vorplatz zeigt Fliesen aus echten Carraramarmor und einem Brunnen ebenfalls aus Carraramarmor und mit echtem fließendem Wasser.
      Auf der linke Seite ist noch ein orientalischer Markt zu betrachten. Die Rückwand mit der Stadt Jerusalem wertet das ganze noch einmal auf.
    </p>
    <p className="normal">
      Die großen Besucherzahlen gaben den Ansporn die Weihnachtskrippe, um einen zusätzlichen Anbau zu  erweitern. In diesem Anbau ist die Herodianische Tempelanlage mit Opfertempel,
      Stadtmauer,und ein Stück Wüste zu betrachten. Auf dem Tempelplatz wird die Opferszene bildlich dargestellt. Zwischenzeitlich ist in der Krippenanlage die Flucht nach
      Ägypten sowie ein echt fließender Grenzfluß hinzugekommen.
    </p>
    <p className="normal">
      Es sind die liebevollen Kleinigkeiten die diese Krippe zu etwas Besonderem machen. Wer eine Münze in den Opferstock wirft, kann die Krippe besonders genau betrachten. Dann
      geht die Beleuchtung an, Glocken läuten, man hört den heimischen Kirchenchor,der aufgebaute Brunnen und der Fluss in der Krippenlandschaft beginnt mit echtem Wasser zu
      plätschern. Die Kripplesgruppe hat sich unter anderem auch zur Aufgabe gemacht die Vorgängerkrippe zu renovieren und ihr einen ehrenhaften Platz auf dem Weg zur Kirche zu geben.
    </p>
    <p className="normal centered">
      <b>Kommen, Sehen, Staunen - sich selbst überzeugen</b>
    </p>
    <Link className="link-back" to="/">Zurück</Link>
  </Layout>
)

export default HistoryPage
